<template>
  <div class="app">
    <div class="top-back-color"></div>
    <div class="bottom-back-color"></div>

    <div class="all">
      <div class="top-wrap">
        <div class="head">
          <div class="left">ARXHE ASSOCIATE</div>
          <div class="mid">
            <img src="../assets/img/icon.svg" />
          </div>
          <div class="right">
            <div>
              <a @click="sectionChange('mail-click')">BY PRIVATE APPOINTMENT</a>
            </div>
            <div class="user" @click="login()">
              <img src="images/user.svg" />
            </div>
          </div>
        </div>

        <div class="video-wrap">
          <img class="left-img" src="../assets/img/left_1.jpg" />
          <div class="video">
            <!-- <video width="860" height="365" controls>
              <source src="https://player.vimeo.com/video/408286203?autoplay=1&color=0D5E57&byline=0&portrait=0" type="video/mp4">
            </video> -->
            <video src="../assets/video/1.mp4" class="video-js vjs-big-play-centered vjs-fluid"
              webkit-playsinline="true" playsinline="true" x-webkit-airplay="allow" x5-playsinline
              style="width: 100%; position: absolute" @play="onPlayerPlay" @pause="onPlayerPause" autoplay loop muted
              ref="video"></video>
            <div style="
                width: 100%;
                height: 435px;
                background-color: black;
                opacity: 0.3;
              "></div>
          </div>
          <img class="right-img" src="../assets/img/right_2.jpg" />
        </div>
      </div>
      <div class="mid-wrap">
        <div class="top-text">
          <!-- <img src="#" align="left" width="88" height="86" hspace="5" vspace="5" onerror="#" /> -->
          <div class="first-phra">
            <div class="first-top">
              <div class="first-top-left">
                <div class="posi">W</div>
              </div>
              <div class="first-top-right">
                <div class="right1 flex-text">
                  <WordSplit sentence="elcome to Arxhe Associates, where we transform the " />
                </div>
                <div class="right1 flex-text">
                  <WordSplit sentence="ordinary into the extraordinary. Our expertise is deeply " />
                </div>
                <div class="right1 flex-text">
                  <WordSplit sentence="rooted in real estate, but our vision stretches" />
                </div>
                <div class="right1 flex-text">
                  <WordSplit sentence="far beyond traditional boundaries. We are on a relentless" />
                </div>
                <div class="right1 flex-text">
                  <WordSplit sentence="quest to merge property with groundbreaking global" />
                </div>
              </div>
            </div>
            <div class="first-bottom">innovations, creating opportunities that redefine the future.</div>
          </div>

          <div class="text-content second-phra">
            <div class="" style="font-size: 28px;font-weight: bold;">A Vision Beyond Real Estate</div>
            <div class="second-phra-text1 flex-text">
              <WordSplit sentence="While our specialty lies in real estate, our passion for" />
            </div>
            <div class="second-phra-text2 flex-text">
              <WordSplit sentence="innovation propels us to explore creative ways to combine " />
            </div>
            <div class="second-phra-text3 flex-text">
              <WordSplit sentence="property with real-world applications. This drive has led us to" />
            </div>
            <div class="second-phra-text4 flex-text">
              <WordSplit sentence="embrace the rapid advancements in artificial intelligence (AI)" />
            </div>
            <div class="second-phra-text5 flex-text">
              <WordSplit sentence="and expand into the supercomputing realm. By entering this" />
            </div>
            <div class="second-phra-text6 flex-text">
              <WordSplit sentence="space, we are not merely adapting to change; we are" />
            </div>
            <div class="second-phra-text7 flex-text">
              <WordSplit sentence="empowering the next wave of AI evolution with unparalleled " />
            </div>
            <div class="second-phra-text8">computational resources.</div>
          </div>

          <div class="text-content third-phra">
            <div class="" style="font-size: 28px;font-weight: bold;">The Journey into Supercomputing</div>
            <div class="third-phra-text1 flex-text">
              <WordSplit sentence="Our decision to venture into supercomputing stems from a" />
            </div>
            <div class="third-phra-text2 flex-text">
              <WordSplit sentence="deep understanding of the technological landscape and its" />
            </div>
            <div class="third-phra-text3 flex-text">
              <WordSplit sentence="for future growth. AI is at the heart of today's most" />
            </div>
            <div class="third-phra-text4 flex-text">
              <WordSplit sentence="significant technological breakthroughs, driving advancements" />
            </div>
            <div class="third-phra-text5 flex-text">
              <WordSplit sentence="across various industries. However, the potential of AI can" />
            </div>
            <div class="third-phra-text6 flex-text">
              <WordSplit sentence="only be fully realised with access to powerful computing" />
            </div>
            <div class="third-phra-text7 flex-text">
              <WordSplit sentence="resources. Recognising this, Arxhe has committed to establishing" />
            </div>
            <div class="third-phra-text8 flex-text">
              <WordSplit sentence="supercomputer data centres in strategic locations such as" />
            </div>
            <div class="third-phra-text9 flex-text">
              <WordSplit sentence="Singapore, Malaysia, Thailand, and Indonesia. These centres," />
            </div>
            <div class="third-phra-text10 flex-text">
              <WordSplit sentence="powered by substantial investments and high-performance" />
            </div>
            <div class="third-phra-text11 flex-text">
              <WordSplit sentence="computing equipment, provide our clients with formidable" />
            </div>
            <div class="third-phra-text12 flex-text">
              <WordSplit sentence="computational capabilities. With cutting-edge hardware" />
            </div>
            <div class="third-phra-text13 flex-text">
              <WordSplit sentence="ensuring efficient and stable services, we lay the foundation" />
            </div>
            <div class="third-phra-text14">
              for groundbreaking innovation.
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="mid-text">
          <div class="mid-text1 flex-text-sec">WE CONNECT OUR CLIENTS TO A</div>
          <div class="mid-text2 flex-text-sec">PRIVILEGED OPPORTUNITY</div>
          <div class="mid-text3 flex-text-sec">
            YIELDING A PRODIGIOUS RETURN
          </div>
          <div class="mid-text4 flex-text-sec">THAT IS UNCOMMON TO THE</div>
          <div class="mid-text5 flex-text-sec">MAINSTREAM.</div>
        </div>
        <div class="line"></div>
        <div class="bottom-text">
          <div class="" style="font-size: 28px;font-weight: bold;">Empowering AI with Supercomputing</div>
          <div class="bottom-text1 flex-text">
            <WordSplit sentence="As AI technology revolutionises industries, the demand for robust" />
          </div>
          <div class="bottom-text2 flex-text">
            <WordSplit sentence="computing power surges. Arxhe stands at the forefront, offering" />
          </div>
          <div class="bottom-text3 flex-text">
            <WordSplit sentence="supercomputer computing power leasing to support businesses" />
          </div>
          <div class="bottom-text4 flex-text">
            <WordSplit sentence="and research institutions. From big data analytics to machine" />
          </div>
          <div class="bottom-text5 flex-text">
            <WordSplit sentence="learning and deep learning, our services empower clients across" />
          </div>
          <div class="bottom-text6 flex-text">
            <WordSplit sentence="healthcare, finance, manufacturing, and technology to achieve" />
          </div>
          <div class="bottom-text7 flex-text">
            <WordSplit sentence="unprecedented breakthroughs. This strategic move into" />
          </div>
          <div class="bottom-text8 flex-text">
            <WordSplit sentence="supercomputing is not just about technology; it's about" />
          </div>
          <div class="bottom-text9 flex-text">
            <WordSplit sentence="enabling our clients to harness AI's full potential to drive" />
          </div>
          <div class="bottom-text10">innovation and growth.</div>
        </div>
        <div class="bottom-text">
          <div class="" style="font-size: 28px;font-weight: bold;">Transforming Soil into Computable Power</div>
          <div class="bottom-text1 flex-text">
            <WordSplit sentence="At Arxhe, we don't just invest in real estate; we revolutionise it." />
          </div>
          <div class="bottom-text2 flex-text">
            <WordSplit sentence="Our strategic investments in Southeast Asia and the" />
          </div>
          <div class="bottom-text3 flex-text">
            <WordSplit sentence="Pan-Asia-Pacific region are anchored by supercomputer centres." />
          </div>
          <div class="bottom-text4 flex-text">
            <WordSplit sentence="This innovative approach transcends traditional real estate" />
          </div>
          <div class="bottom-text5 flex-text">
            <WordSplit sentence="frameworks by turning soil into computable power. By" />
          </div>
          <div class="bottom-text6 flex-text">
            <WordSplit sentence="integrating advanced AI technology with real estate" />
          </div>
          <div class="bottom-text7 flex-text">
            <WordSplit sentence="development, we optimise resource allocation and enhance" />
          </div>
          <div class="bottom-text8 flex-text">
            <WordSplit sentence="property management and operational efficiency. Our projects," />
          </div>
          <div class="bottom-text9 flex-text">
            <WordSplit sentence="from smart office buildings to technology parks, deliver" />
          </div>
          <div class="bottom-text10 flex-text">
            <WordSplit sentence="unparalleled value through intelligent management and" />
          </div>
          <div class="bottom-text11 flex-text">
            <WordSplit sentence="high-efficiency data centre support. This fusion of AI" />
          </div>
          <div class="bottom-text12 flex-text">
            <WordSplit sentence="and real estate exemplifies our investment philosophy:" />
          </div>
          <div class="bottom-text13 flex-text">
            <WordSplit sentence="leveraging the latest innovations to create sustainable and" />
          </div>
          <div class="bottom-text14">high-value assets.</div>
        </div>
      </div>
      <div class="bottom-wrap">
        <img class="bottom-img" src="../assets/img/bottom_3.jpg" />
        <div class="bottom-text">
          <div class="bottom-text-big">
            <div class="bottom-text-big1 flex-text-sec">
              IF YOU WERE TO WRITE YOUR NEXT
            </div>
            <div class="bottom-text-big2 flex-text-sec">
              INVESTMENT CHAPTER, WHO
            </div>
            <div class="bottom-text-big3 flex-text-sec">
              WOULD YOU PARTNER WITH
            </div>
          </div>


          <div class="bottom-text-sma">
            <div style="font-size: 28px;font-weight: bold;">Our Unique Strategic Advantage</div>
            <div class="bottom-text-sma1 flex-text">
              <WordSplit sentence="Our strategic positioning in regions with lower power costs" />
            </div>
            <div class="bottom-text-sma2 flex-text">
              <WordSplit sentence="and significant market potential allows us to provide" />
            </div>
            <div class="bottom-text-sma3 flex-text">
              <WordSplit sentence="cost-effective computing power services. Leveraging the" />
            </div>
            <div class="bottom-text-sma4 flex-text">
              <WordSplit sentence="latest in supercomputer and data centre technology ensures" />
            </div>
            <div class="bottom-text-sma5 flex-text">
              <WordSplit sentence="that our clients receive top-tier service. Our comprehensive" />
            </div>
            <div class="bottom-text-sma5 flex-text">
              <WordSplit sentence="suite of offerings, from computing power leasing to data" />
            </div>
            <div class="bottom-text-sma5 flex-text">
              <WordSplit sentence="storage and analysis, meets diverse client needs. Our team," />
            </div>
            <div class="bottom-text-sma5 flex-text">
              <WordSplit sentence="composed of technical experts and investment elites, provides" />
            </div>
            <div class="bottom-text-sma5 flex-text">
              <WordSplit sentence="professional consulting and services, ensuring our clients" />
            </div>
            <div class="bottom-text-sma6 flex-text">
              benefit from unparalleled expertise.
            </div>
          </div>
          <br>
          <br>
          <div class="bottom-text-sma">
            <div class="bottom-text-sma1 flex-text">
              <WordSplit sentence="At Arxhe, we are not just shaping the future; we are building it." />
            </div>
            <div class="bottom-text-sma2 flex-text">
              <WordSplit sentence="We invite institutional investors and enterprises to connect with" />
            </div>
            <div class="bottom-text-sma3 flex-text">
              <WordSplit sentence="us to learn more about our AI computing power leasing. Discover" />
            </div>
            <div class="bottom-text-sma4 flex-text">
              <WordSplit sentence="how we deliver supercomputing power in a significantly" />
            </div>
            <div class="bottom-text-sma5 flex-text">
              <WordSplit sentence="cost-effective way and explore the strategic advantages we" />
            </div>
            <div class="bottom-text-sma6 flex-text">
              offer. Let’s redefine what’s possible together. </div>
          </div>
        </div>

        <div class="bottom-input-wrap">
          <div class="input-wrap">
            <el-input class="input" v-model="input1" placeholder="Name" />
            <div class="input-line" />
            <el-input class="input" v-model="input2" placeholder="E-mail" />
            <div class="input-line" />
            <el-input class="input" v-model="input3" placeholder="Phone" />
            <div class="input-line" />
            <div id="mail-click" class="mail-click" @click="sengMail">
              Book a private appointment
            </div>
          </div>
        </div>
        <div class="bottom-icon-wrap">
          <!-- <div class="icon icon1">ARXHE ASSOCIATE</div> -->
          <div class="icon icon3">
            <img src="../assets/img/icon3.svg" hidden />
          </div>
          <div class="icon icon4">
            <img src="../assets/img/icon4.svg" hidden />
          </div>
          <div class="icon icon2">
            <img src="../assets/img/icon2.svg" />
          </div>

          <div class="icon icon5">
            <img src="../assets/img/icon5.svg" hidden />
          </div>
          <div class="icon icon6">
            <img src="../assets/img/icon6.svg" hidden />
          </div>
        </div>
        <div class="address">
          <div style="margin-left: 130px">
            Address: Level 5, 4-6 Gold St, Collingwood VIC 3006
          </div>
          <div style="margin-left: 220px">Tel: +61 3 9817 6888</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WordSplit from "../components/WordSplit.vue";
import axios from "axios";
import $ from "jquery";
import { userInfo } from "../http/api";

export default {
  name: "H5Page",
  components: {
    WordSplit,
  },
  data() {
    return {
      routeKsy: "",
      videoOptions: {
        controls: true,
        src: "xxxxxxx.mp4", // url地址
      },
      player: null,
      playTime: "",
      seekTime: "",
      current: "",
      input1: "",
      input2: "",
      input3: "",
    };
  },
  mounted() {
    // alert('我跳转到了H5页面')
    // this.checkPlatform()
    this.initVideo();
  },
  methods: {
    login() {
      userInfo().then();
    },
    checkPlatform() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
      var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
      var bIsMidp = sUserAgent.match(/midp/i) == "midp";
      var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
      var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
      var bIsAndroid = sUserAgent.match(/android/i) == "android";
      var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
      var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
      // alert("您的浏览设备为：");
      if (
        bIsIpad ||
        bIsIphoneOs ||
        bIsMidp ||
        bIsUc7 ||
        bIsUc ||
        bIsAndroid ||
        bIsCE ||
        bIsWM
      ) {
        // document.writeln("phone");
        // alert("您的浏览设备为：phone");
      } else {
        // document.writeln("pc");
        // alert("您的浏览设备为：pc");
        // this.$route.push('')
      }
    },
    initVideo() {
      //原生初始化视频方法
      let myVideo = this.$refs.video;
      //ontimeupdate
      myVideo.ontimeupdate = function () {
        myFunction();
      };
      // let _this = this;

      function myFunction() {
        let playTime = myVideo.currentTime;
        setTimeout(function () {
          localStorage.setItem("cacheTime", playTime);
        }, 500);
        let time = localStorage.getItem("cacheTime");
        // 当前播放位置发生变化时触发。
        if (playTime - Number(time) > 2) {
          myVideo.currentTime = Number(time);
        }
      }
    },
    sectionChange(child) {
      document
        .getElementById(child)
        .scrollIntoView({ block: "start", behavior: "smooth" });
    },
    // 播放回调
    onPlayerPlay(player) {
      // this.globalSetting = true
      console.log("player play!", player);
      // document.getElementsByClassName("vjs-control-bar").style.display = "block";
      // document.getElementsByClassName("vjs-control-bar").style.display = "block";
    },

    // 暂停回调
    onPlayerPause(player) {
      console.log(player);
      // this.globalSetting.controls = false;
      // console.log("player pause!", player);
      // var video = document.getElementById("video");
      // video.controls=false;
      // document.getElementsByClassName("vjs-control-bar").style.display = "none";
    },

    sengMail() {
      // const a = document.createElement('a')
      // const href = `mailto:hello@arxhe.com.au?subject=mail&body=name:${this.input1},e-mail:${this.input2},phone:${this.input3}`
      // console.log(href)
      // a.setAttribute('href', href)
      // document.body.appendChild(a)
      // a.click()

      // 名字非空判断
      if (this.input1 === "") {
        window.alert("Please enter the required information");
        return;
      }

      // 手机号正则
      if (this.input3 === "") {
        window.alert("Please enter the required information");
        return;
      }

      // 邮箱正则
      var re;
      var ss = this.input2;
      re = /\w@\w*\.\w/;
      if (!re.test(ss)) {
        window.alert("Please enter the required information");
        return;
      }
      axios.defaults.baseURL = "http://34.129.216.236:8080";
      // axios.defaults.baseURL = 'http://localhost:8080'
      // axios.post('http://34.129.216.236:8080/sendMail', {
      // axios.post('/sendMail', {
      //   name: this.input2,
      //   email: this.input2,
      //   phone: this.input3
      // }).then(res => {
      //   console.log('res-----', res);
      //   window.alert(res.data.message)
      //   // console.log(1);
      // }, () => {
      //   // window.alert('Mail sent fail!')
      // })
      $.post(
        "http://34.129.216.236:8080/sendMail",
        {
          name: this.input1,
          email: this.input2,
          phone: this.input3,
        },
        (res) => {
          console.log("res-----", res);
          // window.alert(res.message);
          window.alert("Submitted successfully, we will reach you soon");
        }
      );
    },
  },
  watch: {
    $route: function (to) {
      console.log(to);
      if (to) {
        this.routeKsy = to.name;
        sessionStorage.setItem("routeKsy", to.name);
      }
    },
  },
};
</script>

<style lang="scss">
.app {
  width: 100%;
  display: flex;
  position: absolute;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: scale(0.32);
  top: -2180px;
  // background-color: red;

  .top-back-color {
    position: absolute;
    width: 317%;
    height: 980px;
    left: -110%;
    top: 0;
    background-color: #f2f5f8;
    z-index: -1;
  }

  .bottom-back-color {
    position: absolute;
    width: 317%;
    height: 1678px;
    left: -110%;
    bottom: 0;
    background-color: #f2f5f8;
    z-index: -1;
  }

  .all {
    width: 1120px;
    display: flex;
    flex-direction: column;

    .top-wrap {
      background-color: #f2f5f8;
      width: 100%;
      padding: 20px 0;

      .head {
        width: 100%;
        height: 100px;
        // background-color: red;
        display: flex;
        font-size: 12px;
        letter-spacing: 4px;
        justify-content: space-around;
        margin-bottom: 80px;
        font-family: "visiapro-bold";
        font-weight: 900;

        .left,
        .mid,
        .right {
          flex: 0 0 33%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .left {
          justify-content: flex-end;
        }
        .right {
          justify-content: space-between;
        }
        .user img {
          width: 30px;
        }

        a {
          color: #000;
        }
        a:link {
          color: black;
        }
        a:visited {
          color: black;
        }
        a:hover {
          color: blue;
        }

        .mid {
          img {
            height: 60px;
            width: 60px;
          }
        }

        .right {
          text-decoration: underline;
        }
      }

      .video-wrap {
        position: relative;
        width: 100%;
        height: 670px;
        // background-color: red;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 90px;

        .video {
          // width: 860px;
          // height: 485px;
          width: 774px;
          height: 436px;
          // background-color: yellow;
          z-index: 100;
          position: relative;
        }

        .left-img {
          position: absolute;
          left: 0px;
          bottom: -60px;
          height: 342px;
          width: 276px;
        }

        .right-img {
          position: absolute;
          right: 0;
          top: -30px;
          width: 330px;
          height: 417px;
        }
      }
    }

    .mid-wrap {
      background-color: #fff;
      width: 100%;
      padding: 80px 200px;

      .flex-text {
        // display: flex;
        // justify-content: space-between;
      }

      .flex-text-sec {
        display: flex;
        justify-content: space-around;
      }

      .top-text {
        position: relative;
        width: 100%;
        // height: 100px;
        // background-color: red;
        font-size: 22px;
        line-height: 40px;
        font-family: "georgia";
        margin-bottom: 60px;
        // font-weight: bold;

        .first-phra {
          .first-top {
            display: flex;

            .first-top-left {
              position: relative;
              width: 110px;
              height: 120px;
              background-color: #fff;
              z-index: 10;
              line-height: 100px;
              text-align: center;
              font-size: 100px;
              color: rgb(0, 61, 98);

              .posi {
                position: absolute;
                top: 10px;
              }
            }

            .first-top-right {
              flex: 1;
              font-size: 21px;
              color: rgb(76, 73, 72);
            }
          }
        }

        .text-content {
          margin-top: 30px;
          word-break: break-all;
        }
      }

      .line {
        width: 100%;
        height: 1px;
        background-color: gray;
      }

      .mid-text {
        font-size: 34px;
        font-family: "typo";
        color: rgb(76, 73, 72);
        margin: 60px 0;
      }

      .bottom-text {
        width: 100%;
        margin-top: 60px;
        margin-bottom: 120px;
        font-size: 22px;
        line-height: 40px;
        font-family: "georgia";
      }
    }

    .bottom-wrap {
      position: relative;
      // background-color: #f2f5f8;
      width: 100%;
      padding: 180px 220px 80px 220px;

      .bottom-img {
        position: absolute;
        left: 120px;
        top: -120px;
        width: 792px;
        height: 384px;
      }

      .bottom-text {
        margin-top: 280px;

        .bottom-text-big {
          font-size: 32px;
          font-family: "typo";
          color: rgb(76, 73, 72);
          margin: 60px 0;

          .flex-text-sec {
            display: flex;
            justify-content: space-around;
          }
        }

        .bottom-text-sma {
          position: relative;
          width: 104%;
          font-size: 22px;
          line-height: 40px;
          font-family: "georgia";
        }
      }

      .bottom-input-wrap {
        margin-top: 30px;
        width: 100%;
        // height: 50px;
        margin-bottom: 300px;
        display: flex;
        justify-content: center;
        align-items: center;

        .input-wrap {
          width: 420px;

          .input {
            margin-top: 50px;

            .el-input__inner {
              font-family: "georgia";
              text-align: center;
              background-color: transparent;
              border: none;
              color: black;
              font-size: 18px;
            }
          }

          .input-line {
            width: 100%;
            height: 1px;
            background-color: gray;
          }

          .mail-click {
            cursor: pointer;
            margin-top: 30px;
            width: 100%;
            height: 52px;
            background-color: rgb(0, 61, 98);
            color: white;
            font-size: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .bottom-icon-wrap {
        position: absolute;
        width: 100%;
        height: 100px;
        // background-color: red;
        display: flex;
        text-align: center;
        left: -380px;
        bottom: 170px;
        white-space: nowrap;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }

        .icon1 {
          margin-left: 560px;
          font-size: 10px;
          letter-spacing: 2px;
          justify-content: space-around;
          margin-bottom: 80px;
          font-family: "visiapro-bold";
        }

        .icon2 {
            width: 200px;
            height: 80px;
            margin-left: 210px;
        }

        .icon2 {
          img {
            width: 200px;
            height: 80px;
          }
        }

        .icon3 {
            width: 77px;
            height: 35px;
            margin-left: 580px;
        }

        .icon3 {
          img {
            width: 77px;
            height: 35px;
          }
        }


        .icon4 {
            width: 77px;
            height: 35px;
        }

        .icon4 {
          img {
            width: 77px;
            height: 35px;
          }
        }

        .icon5 {
            width: 77px;
            height: 35px;
            margin-left: 40px;
        }
        .icon5 {
          img {
            width: 77px;
            height: 35px;
            margin-left: 40px;
          }
        }

        .icon6 {
            width: 77px;
            height: 35px;
        }

        .icon6 {
          img {
            width: 77px;
            height: 35px;
          }
        }
      }
      .address {
        // display: flex;
        font-size: 12px;
        font-family: "visiapro-bold";
        margin-bottom: 20px;
        margin-left: 20px;
      }
    }

    input::-webkit-input-placeholder {
      color: black;
    }

    input::-moz-input-placeholder {
      color: black;
    }

    input::-ms-input-placeholder {
      color: black;
    }
  }
}
</style>
